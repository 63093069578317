@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.tabHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .rowFlex {
        display: flex;

        .filtersPopoverContainer {
            margin-top: 1rem;
            box-shadow: none;
        }
    }
}

.counterList {
    border-radius: 50%;
    background-color: color("primaryDark");
    color: color("white");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight("bold");
    left: 2px;
    top: -13px;
    position: relative;

    .counterNumber {
        padding-top: 2px;
    }
}

.bold {
    font-weight: bold;
}
.date {
    display: flex;
    font-size: 0.875rem;
    margin-left: 1.75rem;
}
.dateResponsibleColumn {
    display: flex;
    font-size: 0.875rem;
    margin-left: 1.75rem;
}

.statusColumn {
    display: flex;
    font-weight: bold;
    font-style: italic;

    &.colorDanger{
        color: color('danger');
    }

    &.colorSuccess{
        color: color('success');
    }
}

.imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.contentImage {
    width: '5rem';
    height: '3rem';

    @include respond-below(lg) {
        width: '4rem';
        height: '2rem';
    }

    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}