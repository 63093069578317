@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color('white');
    border: 1px solid color('mercury');
    border-radius: 25px;

    .colorInput {
        width: 50px;
        height: 44px;
        border-radius: 50%;
        cursor: pointer;
    }

    input[type='text'] {
        display: block;
        padding: 0.8rem 1rem;
        @include fontWeight('light');
        @include placeholderColor(color('frenchGray'));
        font-size: 16px;
        width: 100%;
        outline: 0;
        border: 1px solid transparent;
        border-radius: 25px;
    }

    &.disabled{
        background-color: color('whisper');

        input[type='text'] {
            background-color: color('whisper');
            opacity: 1;
            cursor: not-allowed;
        }

        .colorInput {
            cursor: not-allowed;
        }
    }
}

.pallete {
    position: fixed;
}