@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.content{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
    height: fit-content;
    z-index: 8;
}

.overlayContainer {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 6;
}

.popoverItem {
    font-size: 0.8rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    padding: 1rem 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: color('lightblack');
    width: 100%;
    overflow: hidden;
    border-radius: 6px;

    &:hover {
        background-color: darken(color('white'), 3.5);
    }
}
