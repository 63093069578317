@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.container {
    display: flex;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    padding: 0.3rem 0.4rem 0.2rem;
}

.containerOn {
    background-color: #D9F3F2;
    border: 1px solid #CBE4E3;

    .info {
        color: #8BB0AE;
    }

    .pin {
        color: #16CCC3;
    }
}
.containerOff {
    background-color: #FDEAD4;
    border: 1px solid #ECDCCA;

    .info {
        color: #BDA182;
    }

    .pin {
        color: #F08000;
    }
}

.content {
    display: flex;
    flex-direction: column;
    margin: 0 0.4rem;
}

.address {
    font-size: 14px;
    color: #000000;
}

.info {
    font-size: 12px;
}

.pin {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.1rem;
    cursor: pointer;
}

.labelAddress {
    // border: 1px solid color("mercury");
    border-radius: 10px;
    font-size: 0.7rem; 
    a {
        color: rgb(0, 0, 0);
        text-decoration: none; /* no underline */
        .small {
            font-size: 0.7rem; 
            
        }
        .linkIcon{
            padding: 0 0.5rem;
        }
    }
}