@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.removeButton {
    position: relative;
    cursor: pointer;
    background-color: color('waitingStatus');
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    .removeIcon {
        width: 16px;
        height: 16px;
        fill: color('white');
    }
}


.light {
    color: gray;
}

.break {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;

    @include respond-below("sm") {
        flex-basis: 100%;
        max-width: 100%;
    }

    @include respond-above("sm") {
        flex-basis: 50%;
        max-width: 50%;
    }
}
