@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
    padding: 1rem 2rem;
    width: 30rem;

    @include respond-below(lg) {
        width: 45vw;
    }

    @include respond-below(md) {
        width: 50vw;
    }

    @include respond-below(sm) {
        width: 65vw;
    }
}

.buttonsFooter {
    text-align: right;
}

.bold {
    @include fontWeight("bold");
}