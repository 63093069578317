@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.box {
    margin-top: 1rem
}

.tabHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.divSVG{
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    margin-right: 4px;
}

.rowFlex{
    display: flex;
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
    left: 2px;
    top: -13px;
    position: relative;

  }

  .counterNumber{
      padding-top: 2px;
  }

  .refundTreatedArea{
      display: flex;
      font-weight: bold;
      font-style: italic;
      font-size: 0.875rem;
  }

  .panelSearch{
      display: flex;
      width: 100%;
      background-color: color('polar');
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin-bottom: 2rem;
      align-items: center;
  }

  .searchValues{
      width: 100%;
      text-align: start;
      @include fontWeight('bold');
      color: color('success');
      font-size: 14px;
  }

.moneyColumn{
    @include fontWeight('bold');
    text-align: right;
}

.costColumn {
    text-align: right;

    .secondRow {
        font-size: 0.9rem;
    }
}

.dateFrais{
    margin-left: 1.8rem;
}

.cellContainer{
    .firstRow{
        font-weight: 400;
    }
    .secondRow{
        font-size: 0.9rem;
        color: color("alto");
    }
}
.bold{
    font-weight: bold;
}

.imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.contentImage {
    width: '5rem';
    height: '3rem';

    @include respond-below(lg) {
        width: '4rem';
        height: '2rem';
    }

    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}

.rowCounters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
    }
}

.importIcon {
    width: 20px !important;
}

.emptyBorder {
    margin-right: 0;
}
