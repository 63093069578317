
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.modalContainer {
    z-index: 10000;
    width: 40%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 3rem 5rem;
    border-radius: 1rem;
    text-align: center;

    

    @include respond-below(lg){
        width: 70%;
    }

    @include respond-below(xs){
        width: 90%;
        padding:1rem;
    }

    .logo {
        max-width: 150px;
        margin-bottom: 2rem;
    }

    .image {
        max-width: 350px;
        margin-top: 1rem;
    }

    .closeButton {
        position: absolute;
        top: 3%;
        right: 2%;
    }

    .slider {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    :global {
        .swiper-pagination-clickable {
            text-align: center;
        }

        .swiper-pagination-bullet {
            border-radius: 50%;
            width: 14px;
            height: 14px;
            background-color: #bebebe;
            position: relative;
            top: 1.5px;
            opacity: 1;
            margin: 0 4px;
        }

        .swiper-pagination-bullet-active {
            background-color: black;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            top: 1.5px;
            opacity: 1;
            margin: 0 4px;
        }
    }

    .title {
        font-weight: 300;
        font-size: 30px;
        margin-bottom: 1rem;
    }

    .subtitle{
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .description{
        font-weight: 300;
    }
}

.pagination {
    margin-top: 2rem;
    display: inline-flex;
}

.checkBoxContainer{
    text-align: left;
    margin-top: 2rem;
}
.container{
    
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkBoxText{
    margin-left:30px;
  }


.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
}


.swiper{
    margin-top: 1rem;
}