@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.titleContainer {
    margin: 2rem 0;
}

.textTitle {
    font-size: 18px;
    font-weight: 'bold';
    @include fontWeight('bold');
    margin-bottom: 10px;
    color: color('black');
}

.buttons_container {
    margin: 0.5em 0;
    text-align: right;
}

.bold {
    font-weight: bold;
}