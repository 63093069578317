@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.box {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .header {
        font-size: 1.5rem;
        @include fontWeight("bold");
        margin-bottom: 2rem;

        .icon {
            cursor: pointer;
            width: 1.5rem;
            margin-right: 1rem;
            transform: rotate(-180deg);
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        //margin: 1rem 10rem 1rem 1rem;
        margin: 1rem;

        @include respond-below(lg) {
            margin: 1rem;
        }
    }

    .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }

        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;

            @include respond-below(lg) {
                font-size: 0.7rem;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }
}

.header {
    font-size: 1.2rem;
    @include fontWeight("bold");
    margin-bottom: 2rem;

    .icon {
        cursor: pointer;
        width: 1.5rem;
        margin-right: 1rem;
        transform: rotate(-180deg);
    }
}

.label {
    font-size: 1rem;
    margin: 0.67em 0;
    @include fontWeight("bold");
    color: color("black");
}

.buttonsContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }

    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}

.checkboxArea {
    padding: 0.5rem 0;
}

.addIcon {
    padding: 0 1rem 0 0;
}

.fileArea {
    align-items: center;
    display: flex;
    justify-content: center;
}

.groupRefundTreated {
    display: flex;
}

.groupSpinner {
    width: 50%;
}

.groupInputRefundTreated {
    width: 50%;
}

.spinner {
    display: flex;
    @include fontWeight("light");
    @include placeholderColor(color("frenchGray"));
    font-size: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;

    .labelSpinner {
        text-align: center;
        .selected {
            color: color("white");
            background-color: color("primaryDark");
            padding: 0.7rem 1rem;
        }

        .notSelected {
            color: color("black");
            background-color: color("white");
            padding: 0.7rem 1rem;
        }
    }
}

.spinnerDetails {
    display: flex;
    @include fontWeight("light");
    @include placeholderColor(color("frenchGray"));
    font-size: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 25px;
    overflow: hidden;

    .labelSpinner {
        text-align: center;
        .selected {
            color: color("white");
            background-color: color("primaryDark");
            padding: 0.7rem 1rem;
        }

        .notSelected {
            color: color("black");
            background-color: color("white");
            padding: 0.7rem 1rem;
            background-color: #e8e8e8;
        }
    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
    left: 2px;
    top: -13px;
    position: relative;
  }

.counter {
    border-radius: 50%;
    border: 1px solid color('primaryDark');
    background-color: color('white');
    color: color('primaryDark');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight('bold');
  }

.counterNumber{
    padding-top: 2px;
}

.datepickerRow {
    width: 11rem;
}

.badgeRow {
      margin: 0 1rem 0 0;
      width: 8rem;
}

.sizeBadge{
    width: 8rem;
    text-align: center;
}

.dateArea{
      padding-left: 0.5rem;
}

.refundTreatedArea{
      display: flex;
}

.panelSearch{
      display: flex;
      width: 100%;
      background-color: color('polar');
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin-bottom: 2rem;
}

.searchValues{
      width: 40%;
      text-align: start;
      @include fontWeight('bold');
      color: color('success');
      font-size: 14px;
}

.searchResults{
    width: 60%;
    text-align: end;
    color: color('primaryDark');
    font-size: 11px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.itemResults{
    padding-left: 1rem;
}

.moneyColumn{
    text-align: right;
    @include fontWeight('bold');
}


.rowCounters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
    }

}