@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.bold {
    @include fontWeight("bold");
}

.formContent {
    width: 100%;
    padding: 1rem 2rem;
    max-width: 40vw;

    @include respond-below(lg) {
        max-width: 45vw;
    }

    @include respond-below(md) {
        max-width: 50vw;
    }

    @include respond-below(sm) {
        max-width: 65vw;
    }
}

.buttonsFooter {
    text-align: right;
}

.dateRangeCustom {
    display: inline-flex;
    justify-content: space-between;
}

.groupSpinner {
    width: 100%;
}

.spinner {
    display: flex;
    @include fontWeight("light");
    @include placeholderColor(color("frenchGray"));
    font-size: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;

    .labelSpinner {
        text-align: center;
        .selected {
            color: color("white");
            padding: 0.7rem 1rem;
        }

        .notSelected {
            color: color("black");
            background-color: color("white");
            padding: 0.7rem 1rem;
        }
    }
}
