@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.box {
    margin-top: 1rem
}

.firstRow {
    @include fontWeight('bold');
    font-size: 1.125rem;
}

.secondRow,
.columnSpec {
    font-size: 0.875rem;
}


.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }

    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
    left: 2px;
    top: -13px;
    position: relative;
}

.counter {
    border-radius: 50%;
    border: 1px solid color('primaryDark');
    background-color: color('white');
    color: color('primaryDark');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight('bold');
}

.counterNumber {
    padding-top: 2px;
}

.imageDescCell {
    display: flex;

    .descCell {
        margin-left: 0.8rem;
        display: flex;
        align-items: center;
    }
}

.emailDriver {
    margin-left: 1.8rem;
    font-size: 0.875rem;
}

.rowCounters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
    }
}

.bold {
    font-weight: bold;
}