@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.box{
    width: 100%;
    display: flex;
    margin-bottom: 2rem;

    .header {
        font-size: 1.5rem;
        @include fontWeight('bold');
        margin-bottom: 2rem;

        .icon{
            cursor: pointer;
            width: 1.5rem;
            margin-right: 1rem;
            transform:  rotate(-180deg);
        }
    }

    .content{
        display: flex;
        flex-direction: row;
        margin: 1rem;

        @include respond-below(lg) {
            margin: 1rem;
        }

        .condition{
            display: flex;
            @include fontWeight('light');
            @include placeholderColor(color('frenchGray'));
            border: 1px solid #EAEAEA;
            border-radius: 25px;
            cursor: pointer;
            overflow: hidden;
            width: 100%;

            @include respond-below(lg) {
                font-size: 0.8rem;
            }

            .labelContainer{ 
                text-align: center;
                height: 100%;
                
                .selected{   
                    color: color('white');
                    background-color: color('primaryDark');
                    padding: 0.5rem 1rem;
                    word-break: keep-all;  

                    @include respond-below(lg) {
                        padding: 0.7rem 1rem;
                    }
                }

                .notSelected{
                    color: color('black');
                    background-color: color('white');
                    padding: 0.5rem 1rem;
                }

                .labelContent {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }
        
        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;

            @include respond-below(lg) {
                font-size: 0.7rem;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }
    
}

.fullWidth {
    width: 100%;
}

.warningInput{
    border: 1px solid color('webOrange');
}

.warningLabel{
    color: color('webOrange');
    font-size: 0.875rem;
    @include fontWeight('light');
}

.label{
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}

.errorLabel{
    display: block;
    @include fontWeight('light');
    font-size: 14px;
    margin-bottom: 4px;
    color: color('danger');
}

.section{
    display: flex;
    width: 100%;
    background-color: color('grey');
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    align-items: center;
    text-align: start;
    @include fontWeight('bold');
    color: color('black');
    font-size: 14px;
    border: 1px solid  color('gallery');;
    
    
    .sectionTitle {
        width: 100%;
        text-align: start;
        @include fontWeight('bold');
        color: color('black');
        font-size: 14px;
    }
}