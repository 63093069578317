@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.tabHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rowFlex{
    display: flex;
}

.flexColumn {
    flex-direction: column;
}

.keepText{
    font-weight: 'bold';
    white-space: 'nowrap' !important;
    word-break: 'keep-all';
}

.button{
    cursor: pointer;
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.divSVG {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
}


.responsible{
    display: flex;
    align-items: left;
    flex-direction: column;

    .icon{
        margin-right: 0.5rem;
        width: 1rem;
    }

    .secondRow{
        font-size: 0.9rem;
    }
}

.costColumn {
    .secondRow {
        font-size: 0.9rem;
    }
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
  }

.counter {
    border-radius: 50%;
    border: 1px solid color('primaryDark');
    background-color: color('white');
    color: color('primaryDark');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight('bold');
  }

  .counterNumber{
      padding-top: 2px;
  }


  .imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.contentImage {
    width: '5rem';
    height: '3rem';

    @include respond-below(lg) {
        width: '4rem';
        height: '2rem';
    }

    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
    white-space: 'nowrap' !important;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}

  
.primaryLight {
    color: color('primaryLight');
}

.primaryLightInverted {
    background-color: color('success');
}

.primaryDark {
    color: color('primaryDark');
}

.danger {
    color: color('danger');
}

.success {
    color: color('success');
}

.warning {
    color: color('warning');
}

.inactive {
    color: color('lightGrayishBlue');
}

.default {
    color: color('frenchGray');
}
.hideText{

    @include respond-above(xxl) {
        display: none;
    }
}

.cellContainer{
    .firstRow{
        font-weight: 400;
    }
    .secondRow{
        font-size: 0.9rem;
    }
}
