@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.verificationInfos {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.label{
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}