@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.counters {
    margin-bottom: 1rem;
}

.rowCounters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
    }
}