@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.box {
    width: 100%;
    //display: flex;
    margin-bottom: 2rem;

    .header {
        font-size: 1.5rem;
        @include fontWeight("bold");
        margin-bottom: 2rem;

        .icon {
            cursor: pointer;
            width: 1.5rem;
            margin-right: 1rem;
            transform: rotate(-180deg);
        }
    }

    .form {
        flex: 1;
        margin-top: 1.5rem;

        .label {
            font-size: 0.9rem;
            @include fontWeight("bold");
        }
    }
    .image {
        flex: 0.2;
        padding: 15px;
    }

    .contentImage {
        padding: 15px;
        .icon {
            float: right;
            cursor: pointer;
        }
        .img {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include respond-below(lg) {
                width: 100%;
                min-height: 12rem;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        margin: 1rem;

        @include respond-below(lg) {
            margin: 1rem;
        }

        
    }

    .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }

        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;

            @include respond-below(lg) {
                font-size: 0.7rem;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }
}

.header {
    font-size: 1.2rem;
    @include fontWeight("bold");
    margin-bottom: 2rem;

    .icon {
        cursor: pointer;
        width: 1.0625rem;
        margin-right: 0.3rem;
        transform: rotate(-180deg);
        filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
        opacity: 1;
    }
}

.headerPlans {
    background-color: color('grey');
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .headerText {
        font-size: 1.125rem;
        @include fontWeight('bold');
    }

    .headerButton {
        cursor: pointer;

        .headerButtonIcon {
            width: 21px;
            height: 21px;
        }
    }
}
