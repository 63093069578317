@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";

.noEnoughInfoText {
	font-size: 1rem;
	padding: 1rem 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.container {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.chartContainer {
	position: relative;
	flex: 1;
	overflow: hidden;
}

.countersContainer {
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
}

.counter {
	flex: 1;
	padding: 0 0.6rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.counterLabel {
	font-size: 0.9rem;
	font-weight: 300;
	margin: 0;
	padding: 0;
	margin-bottom: 0.5rem;
}

.counterValue {
	font-size: 1.1rem;
	font-weight: bold;
	margin: 0;
	padding: 0;
}

.counterRightBorder {
	border-right: 1px solid color('gray5');
}