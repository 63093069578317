.chartCol {
    display: flex;
}

.chartContainer {
    flex: 1;
    margin-bottom: 1rem;
    height: 320px; //380px
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .chartBody {
        position: relative;
        height: 100%;
        overflow: hidden;
        display: flex;
    }
}

.centerAlign {
	font-size: 1rem;
	padding: 1rem 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}