@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.titleContainer {
    margin-bottom: 17px;
}

.textTitle {
    font-size: 20px;
    font-weight: 'bold';
    @include fontWeight('bold');
    margin-bottom: 10px;
    color: color('primaryDark');
}

.messageContainer {
    margin-bottom: 20px;
}

.textMessage {
    font-size: 16px;
    color: 'blue';
}

.buttons_container {
    margin-top: 1em;
    text-align: right;
};