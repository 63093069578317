@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.subtitle {
    color: color('grayAmerica');
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.label {
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}

.dateRangeCustom {
    display: inline-flex;
    justify-content: space-between;
    width: 100% !important;
}




.displayBetween {
    display: flex;
    justify-content: space-between;
}

.displayCenter {
    display: flex;
    justify-content: center;
}


.timelineItem {
    position: relative;
    padding-left: 1.6rem;
    margin-bottom: 1rem;
}

.circle {
    position: absolute;
    left: 2px;
    top: 0;
    width: 14px;
    height: 14px;
    background-color: color('primaryLight2');
    border-radius: 50%;
    border: 1px solid color('primaryLight2');
    z-index: 1;
}

.timelineItem::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 15px;
    width: 1px;
    height: 135%;
    background: color('gray');
    z-index: 0;
}

.timelineItem:last-child::before {
    height: 0;
}


.dateTime {
    color: color('black');
    margin-bottom: 0;
    font-weight: bold;
    font-size: smaller;
}

.file {
    display: inline-flex;
    font-size: smaller;
    margin-top: 0.2rem;
}

.removeIconDiv {
    display: flex;
    align-items: center;
}

.buttonsContainer{
    display: flex;
    justify-content: end;
}

.container{
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: thin;
    overflow-x: hidden;
    @include respond-below(lg) {
        max-height: 75vh;
    }
}