@import "../../../styles/utils/responsive.scss";

.content {
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    outline: 0 none;
    min-width: 60%;
    max-width: 90%;
    padding: 1rem;
    overflow-y: auto;

    @include respond-below(lg) {
        min-width: unset;
    }
}