@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container {
    padding: 0 1rem;
    position: relative;

    .removeButton {
        position: relative;
        cursor: pointer;
        background-color: color('waitingStatus');
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;

        .removeIcon {
            width: 16px;
            height: 16px;
            fill: color('white');
        }
    }

    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }
}