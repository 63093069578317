@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include respond-below(md) {
        flex-direction: column;
        margin-bottom: 0.5rem;
    }
}

.title {
    flex: 1;
    @include fontWeight('bold');
    font-size: 1.8rem;
    color: color('black');
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}