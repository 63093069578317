@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container{
    padding: 0 1rem;

    .tabHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rowFlex{
            display: flex;

            .filtersPopoverContainer{
                margin-top: 1rem;
            }
        }
    }
}

.counter{
    border-radius: 50%;
    border: 1px solid color('primaryDark');
    background-color: color('white');
    color: color('primaryDark');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight('bold');
}

.counterNumber{
      padding-top: 2px;
}

.moneyColumn{
    text-align: right;
    @include fontWeight('bold');
}

.counterList {
    border-radius: 50%;
    background-color: color("primaryDark");
    color: color("white");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight("bold");
    left: 2px;
    top: -13px;
    position: relative;

    .counterNumber {
        padding-top: 2px;
    }
}