@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";


.header {
    font-size: 1.2rem;
    @include fontWeight('bold');
    margin-bottom: 2rem;
    flex: 1;

    .icon{
        cursor: pointer;
        width: 1.0625rem;
        margin-right: 0.3rem;
        transform:  rotate(-180deg);
        filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
        opacity:1
    }
}



.colRightContent{
    padding: 1rem;
    background-color: color('grey');

    .subtitle{
        color: color('grayAmerica');
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }
}

.content{    
    padding: 1rem;
    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }

    .selectOption{
        color: color('black');

        padding: 0.8rem 1rem;
        @include fontWeight('light');
        @include placeholderColor(color('frenchGray'));
        font-size: 16px;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAEAEA;
        border-radius: 25px;
        opacity: 1;

        .selected{
            color: color('white');
            background: color('primaryDark');
        }
    }
    .spinner{
        display: flex;
        @include fontWeight('light');
        @include placeholderColor(color('frenchGray'));
        font-size: 16px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAEAEA;
        border-radius: 25px;
        cursor: pointer;
        overflow: hidden;

        .labelSpinner{ 
            text-align: center;
            .selected{   
                color: color('white');
                background-color: color('primaryDark');
                padding: 0.7rem 1rem;
            }

            .notSelected{
                color: color('black');
                background-color: color('white');
                padding: 0.7rem 1rem;
            }
        }
    }

    .spinnerDetails{
        display: flex;
        @include fontWeight('light');
        @include placeholderColor(color('frenchGray'));
        font-size: 16px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAEAEA;
        border-radius: 25px;
        overflow: hidden;

        .labelSpinner{ 
            text-align: center;
            .selected{   
                color: color('white');
                background-color: color('primaryDark');
                padding: 0.7rem 1rem;
            }

            .notSelected{
                color: color('black');
                background-color: color('white');
                padding: 0.7rem 1rem;
            }
        }
    }
}


.buttonsContainer {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }
    
    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}

.groupInputsWarranty{
    display: flex;
    flex-wrap: wrap;

    @include respond-below(lg) {
        flex-direction: column;
    }
}

.groupSpinner{
    width: 50%;
    min-width: 8rem;
}

.checkboxArea{
    padding: 0.5rem 0;
}

.addIcon{
    padding: 0 1rem 0 0;
}

.fileArea{
    align-items: center;
    display: flex;
    justify-content: center;
}

.dateRangeCustom {
    display: inline-flex;
    justify-content: space-between;
    width: 100% !important;
}

.messageAlert{
    color: color('primaryDark');
    font-size: 0.9rem;
}



.containerSmallButton{
    display: inline-flex;
    align-items: center;
    
    .iconAdd{
        width: 1rem;
    }
}

.rowAlerts{
    margin-bottom: 0.1rem;
}

.removeIcon {
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    fill: color('white');
    cursor: pointer;
}

.noInfoText {
    font-size: 0.9rem;
}