@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";



.tabHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rowFlex {
    display: flex;
}

.flexColumn {
    flex-direction: column;
}

.keepText {
    font-weight: 'bold';
    white-space: 'nowrap';
    word-break: 'keep-all';
}

.button {
    cursor: pointer;
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}


.responsible {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 0.5rem;
        width: 1rem;
    }
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
}

.counterNumber {
    padding-top: 2px;
}

.imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}


.primaryLight {
    color: color('primaryLight');
}

.primaryLightInverted {
    background-color: color('success');
}

.primaryDark {
    color: color('primaryDark');
}

.danger {
    color: color('danger');
}

.success {
    color: color('success');
}

.warning {
    color: color('warning');
}

.inactive {
    color: color('lightGrayishBlue');
}

.default {
    color: color('frenchGray');
}
