@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.bold {
    @include fontWeight("bold");
}

.formContent {
    width: 100%;
    padding: 1rem 2rem;
    max-width: 30vw;

    @include respond-below(lg) {
        max-width: 35vw;
    }

    @include respond-below(md) {
        max-width: 40vw;
    }

    @include respond-below(sm) {
        max-width: 55vw;
    }


    .details{
        display: flex;
        padding-bottom: 0.8rem;
    
        .image {
            display: inline-flex;
            align-items: center;
    
            .contentImage {
                .img {
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
            
                    @include respond-below(lg) {
                        width: 100%;
                    }
                }
            }
        }
        .description {
            margin-left: 0.8rem;
    
            .bold {
                @include fontWeight("bold");
                font-size: 1.125rem;
            }

            .smallLabel{
                font-size: 0.875rem;
            }
        }   
    
    }

    .divider{
        border-top: 2px solid color("gallery");
        padding-bottom: 1.5rem;
    }

    .label{
        @include fontWeight("bold");
        font-size: 0.875rem;

    }

    .warningInput{
        border: 1px solid color('waitingStatus');
        border-radius: 25px;
    }
    
    .warningLabel{
        color: color('waitingStatus');
        @include fontWeight("bold");
        font-size: 0.875rem;
    }
    .warningMessage{
        color: color('waitingStatus');
        font-size: 0.75rem;
        @include fontWeight("bold");
        padding:0.2rem 0 0.5rem 0;
    }
}


.buttonsFooter {
    text-align: right;
}