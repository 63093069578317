@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.informationHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .infoBadge {
        margin-right: 1rem;
        padding: 0.5rem 1.1rem !important;
        @include fontWeight('bold');
        font-size: 0.75rem;
        cursor: pointer;
    }
}

.inputContainer {
    width: 20rem;
}
