.noEnoughInfoText {
	font-size: 1rem;
	padding: 1rem 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.chartContainer {
	position: relative;
	flex: 1;
	overflow: hidden;
}
