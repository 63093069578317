@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.formController {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: -.5rem;

    & > * {
        flex: 1 1 0;
        margin: 0.5rem
    }
}
