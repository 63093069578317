@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.container {
    margin-bottom: 2rem;
}

.header {
    background: color('gallery');
    border: 1px solid color('gallery');
    border-radius: 10px 10px 0px 0px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headerText {
        font-size: 0.875rem;
        @include fontWeight('bold');
    }

    .headerButton {
        cursor: pointer;

        .headerButtonText {
            font-size: 0.7rem;
            color: color('success');
            margin-right: 0.5rem;
        }
        
        .headerButtonIcon {
            width: 26px;
            height: 26px;
        }
    }
}

.noAnnotationsText {
    text-align: center;
    padding: 1rem;
    border: 1px solid color('gallery');
    border-top: 0;
    border-radius: 0px 0px 10px 10px;
}