@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";

.optionsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.year {
		font-weight: bold;
		text-align: center;
		font-size: 0.9rem;
		padding: 0 0.6rem;
	}
}

.centerAlign {
	font-size: 1rem;
	padding: 1rem 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}