@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

$itemHeight: 42px;
$itemWidth: 62px;

.container {
    width: 100%;
}

.list {
    display: flex;
    flex-direction: row;
    align-items: center;

    .addMedia {
        width: $itemHeight;
        height: $itemHeight;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: color('success');
        cursor: pointer;
        border-radius: 50%;
        margin-right: 0.8rem;

        .addMediaIcon {
            color: color('white');
            font-size: 1.1rem;
        }
    }

    .itemPreview {
        width: $itemWidth;
        height: $itemHeight;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid color("mercury");
        margin-right: 0.5rem;
        cursor: pointer;
        border-radius: 6px;
        position: relative;
        transition: opacity 0.3s;

        .removeButton {
            $iconSize: 20px;
            position: absolute;
            top: -$iconSize/2;
            right: -$iconSize/2;
            cursor: pointer;
            background-color: color('waitingStatus');
            border-radius: 50%;
            width: $iconSize;
            height: $iconSize;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;

            .removeIcon {
                width: 12px;
                height: 12px;
                fill: color('white');
            }
        }

        &:hover .removeButton {
            opacity: 1;
        }
    }

    .noItemsText {
        margin-top: 0.6rem;
        font-size: 0.85rem;
    }
}
