@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.title {
    font-size: 1.1rem;
    @include fontWeight('bold');
}

.infoSmall {
    font-size: 0.7rem;
    white-space: nowrap;
    color:  color("frenchGray");
    @include fontWeight('normal');
}

.info {
    font-size: 0.8rem;
    white-space: nowrap;
    @include fontWeight('normal');

    .inlineInfo {
        display: flex;
        align-items: center;
    }

    .icon {
        margin-right: 0.3rem;
    }
}
