@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
}

.dropdownContainer {
    right: -40px;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color("primaryDark");
    color: color("white");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight("bold");

    left: 2px;
    top: -13px;
    position: relative;
}

.counter {
    border-radius: 50%;
    border: 1px solid color("primaryDark");
    background-color: color("white");
    color: color("primaryDark");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight("bold");
}

.counterNumber {
    padding-top: 2px;
}

.imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.rowCounters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
    }
}

.cellContainer{
    .firstRow{
        font-weight: bold;
    }
    .secondRow{
        font-size: 0.9rem;
        color: color("alto");
    }

    .smallCustomization{
        font-size: 0.9rem;
    }

    .smallCustomizationDanger{
        font-size: 0.9rem;
        color: color('danger');
    }
}

.kilometersContainer{
    display: inline-flex;
    align-items: center;
    .imgKilometers {
        margin-left: 0.5rem;
        height: 1rem;
        width: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.fontBold{
    font-weight: bold;
}

.progressBar{
    margin-bottom: 3px;
    width: 80%;
    height: 0.25rem;
    background-color: color("alto");

    .progressBarItem{
        height: 100%;

        &.progressBarItemAbove{
            background-color: color("danger");
        }
        &.progressBarItemBelow{
            background-color: color("primaryLight");
        }
    }
}

.kmsRealAbove{
    color: color("danger");
}
.kmsRealBelow{
    color: color("primaryLight");
}

.exportButton{
    cursor: pointer;
    margin: 0 1rem;
    margin-right: 0;
}

.toolTipDivFitContent{
    width:fit-content;
}