@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container{
    display: inline-flex;
    align-items: center;
    
    .icon {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &.iconSmall{
            height: 0.75rem;
            width: 0.75rem;
        }

        &.iconLarge{
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}