@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.bold {
    @include fontWeight("bold");
}

.formContent {
    width: 100%;
    padding: 1rem 2rem;
    max-width: 40vw;

    @include respond-below(lg) {
        max-width: 45vw;
    }

    @include respond-below(md) {
        max-width: 50vw;
    }

    @include respond-below(sm) {
        max-width: 65vw;
    }
}

.buttonsFooter {
    text-align: right;
}
