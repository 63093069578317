@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

$itemVerticalPadding: 1.2rem;
$itemHorizontalPadding: 1.5rem;

.container {
    width: 100%;

}

.list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .listItem {
        padding: 0.5rem 1rem;
        cursor: pointer;
        word-break: initial;
        font-size: 0.875rem;
        margin-right: 0.5rem;
        border-top: 1.5px solid transparent;
        flex: 1;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }

        &.listItemActive {
            @include fontWeight("bold");
            border-top-color: color("primaryLight");
            background-color: color("white");
        }
    }
}

.content {
    background-color: color("white");
    box-shadow: 0px 2px 10px #0000001A;

    .message {
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
    }
}