@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.button {
    cursor: pointer;
    margin: 0 1rem;

    &:last-child {
        margin-right: 0;
    }

    img {
        width: 24px;
        height:auto;
    }
}
