@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: color("grey");

    .tab {
        padding: 1rem;
        cursor: pointer;

        &.active {
            background-color: color("white");
            border-bottom: 0;
            color: color("primary");
        }

        .invalid{
            color: color('danger');
            font-size: 0.75rem;
            margin: 0 0.25rem 0.25rem 0;
        }

        .languageDefault{
            color: color('frenchGray');
            font-size: 1rem;
            margin: 0 0.25rem 0.25rem 0;
        }
    }
}
