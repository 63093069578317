@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
}

.subContainer {
    border-width: 1;
    border-color: color('danger');
    background-color: color('danger');
    border-radius: 12px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0.20rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.icon {
    height: 0.60rem;
}

.counter {
    font-size: 0.8rem;
    text-align: center;
    color: color('white');
    display: block;
    margin-left: 0.2rem;
}


.iconSmall {
    height: 0.6rem;
}

.counterSmall {
    font-size: 0.7rem;
    vertical-align: middle;
}