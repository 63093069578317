@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.containerEdit {
    margin-bottom: 1rem;
    background: color('alabasterSolid');
    border: 1px solid color('gallery');
    border-radius: 10px;
    position: relative;
    padding: 1rem;

    &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.containerError {
        @include elevation(4, #dc4c4c);
    }

    .removeButton {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        background-color: color('waitingStatus');
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;

        .removeIcon {
            width: 16px;
            height: 16px;
            fill: color('white');
        }
    }

    .label {
        text-align: left;
        font: normal normal bold 14px/17px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 0.8rem;
    }

    .rowSpace {
        margin-bottom: 1rem;
    }

    .addIcon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-left: 0.5rem;
    }
}

.containerDetails {
    margin-bottom: 1rem;
    padding: 0.5rem 0.5rem 0 0.5rem;
    background: color('alabasterSolid');
    border: 1px solid color('alto');
    border-radius: 10px;
    position: relative;
    
    &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .rowSpace {
        margin-bottom: 1rem;
    }

    .label {
        text-align: left;
        font: normal normal bold 14px/17px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 0.8rem;
    }

    .rowSpaceDescription {
        padding: 0.5rem 0.5rem 1rem 0.5rem;

        .rightInfo{
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .statusContainer {
                position: relative;
                
                .status{
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    font-size: 0.75rem;
                    color: #AEAEAE;
                    font-style: italic;
                    padding: 0.1rem 0.4rem;
                    text-align: center;
                }
            }

        }
    }

    .rowSpaceForm {
        padding: 0 0.5rem;
        margin-bottom: 1rem;
    }

    .rowSpaceSelects {
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        background-color: color('gallery');
        border-radius: 0 0 10px 10px;

        
        .addIcon {
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-left: 0.5rem;
        }

        .spinner {
                display: flex;
                @include fontWeight('light');
                border: 1px solid #EAEAEA;
                border-radius: 25px;
                overflow: hidden;
                width: 100%;
                cursor: pointer ;
    
                @include respond-below(lg) {
                    font-size: 0.8rem;
                }
    
                .labelContainer{ 
                    text-align: center;
                    height: 100%;
                    
                    .selected{   
                        color: color('white');
                        padding: 0.6rem 1rem;
                        word-break: keep-all;  
    
                        @include respond-below(lg) {
                            padding: 0.7rem 1rem;
                        }
                    }
    
                    .notSelected{
                        color: color('black');
                        background-color: color('white');
                        padding: 0.6rem 1rem;
                    }
    
                    .labelContent {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
        }
    }

    .rowDivider {
        border-top: 1px solid color('alto');
        padding-top: 1rem;
    }
    
}