@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color("primaryDark");
    color: color("white");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight("bold");

    left: 2px;
    top: -13px;
    position: relative;
}

.counterNumber {
    padding-top: 2px;
}

.containerCards {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    flex-wrap: wrap;
}

.fontBold{
    font-weight: bold;
}

.inputSearchContainerCustomization{
    width: 20rem; 
}

.emptyBorder {
    margin-right: 0;
}

.importIcon { 
    width: 20px !important;
}

.buttonAdd { 
    margin: 0 0.5rem;
}
