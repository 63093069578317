@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    position: relative;
    padding: 1rem;
}

.noDataContainer {
    position: relative;
    padding: 1rem;
    text-align: center;
}

.floatingBox {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: color('white');
    z-index: 500;
    padding: 2rem;
    box-shadow: 0px 3px 6px #00000029;
    max-width: 25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        color: color('toastedYellow');
        font-size: 3rem;
        padding-right: 2rem;
        width: 5rem;
    }

    .address {
        flex: 1;
        font-size: 1.1rem;
    }
}

.onFg {
    z-index: 1001;
}

.errorMessage {
    color: color('danger');
    @include fontWeight('bold');
    text-align: center;
    padding: 2rem;
    margin-top: 10px;
}

.map {
    display: flex;
    flex: 1;
    height: 83dvh;
    margin-top: 10px;
    border: solid 1px #DDDDDD;
    border-radius: 4px;
    padding: 0.5rem;
}

.pr {
    padding-right: 0.2rem;
}

.mr {
    margin-right: 0.2rem;
}

.p08 {
    padding: 0.8rem;
}

.cardBody {
    flex: 1 1 auto;
    padding: 1rem;
    font-size: 0.85em;
    .icon {
        margin-right: 0.2rem;
        height: 1rem;
        width: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;

        &.iconSmall {
            height: 0.75rem;
            width: 0.75rem;
        }

        &.iconLarge {
            height: 1.25rem;
            width: 1.25rem;
        }
    }

    .inputGroup {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;

        &:first-of-type {
            margin-bottom: 1rem !important;
        }

        .input {
            flex: 1;
            padding: 5px;
            background-color: #FFFFFF;
            border: 1px solid #EAEAEA;
            border-radius: 10px;
        }
    }

}

.cardFooter {
    padding: 0 1rem 1rem;
    font-size: 0.85em;
    .icon {
        margin-right: 0.2rem;
        height: 1rem;
        width: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;

        &.iconSmall {
            height: 0.75rem;
            width: 0.75rem;
        }

        &.iconLarge {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}

.card {
    background-color: #F4F6F8;
    border: 1px solid rgb(0, 0, 0, .175);
    border-radius: 0.375rem;
    margin-bottom: 0.5rem !important;
    display: flex;
    flex-direction: column;
    color: #212529;
    word-wrap: break-word;
    background-clip: initial;
    cursor: pointer;

    &.selected {
        background-color: #D0E9EF;
        border-width: 3px;
    }
}


.emptyContainer {
    margin-bottom: 0.5rem !important;
    display: flex;
    flex-direction: column;
    color: #212529;
    word-wrap: break-word;
    background-clip: initial;
    align-items: center;
}

.badge {
    background-color: #FFF;
    padding: 2px 5px;
    display: flex;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    white-space: nowrap;
}

.spaceBetween {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.geoLocation {
     height: 67dvh;
    // padding: 0;
    // overflow-y: scroll;

    flex: 1;
    overflow: auto;
    @include scrollbars();

    .sourcePin {
        padding: 5px;
        color: #67BD4C;
    }

    .destinationPin {
        padding: 5px;
        color: #D22222;
    }
}


.eco {
    padding: 2px 5px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    &.eco_success {
        border: 1px solid #D8E4D3;
        background-color: #E7F2E2;
        color: #73C25C;
    }

    &.eco_danger {
        background-color: #FFDEDE;
        background-color: #F0C9C9;
        color: red;
    }

    &.eco_warning {
        background-color: #FFF8E0;
        background-color: #E6DDBC;
        color: orange;
    }
}

.currentLocation {
    width: 100%;
}

.hr {
    border: 1px #E3E3E3 solid;
    border-radius: 1px;
}

.labelAddress {
    font-size: 1rem;
}

.labelDate {
    color: #B4B4B4;
    font-size: 0.75rem;
}

.infoFuel {
    color: #B4B4B4;
    font-size: 0.75rem;
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .labelFuel {
        margin-left: 5px;
    }
}

.iconLine {
    width: 2px;
    height: 85%;
    border-left: 1px dashed #707070;
    margin: 0 10px;
    position: absolute;
    top: 31px;
    left: 2px;
}

.moving {
    color: #16CCC3;
    background-color: #D9F3F2;
    border: 1px solid #16CCC3;
    border-radius: 4px;
    font-size: 0.70rem;
    padding: 0 0.3rem;
    width: fit-content;
    margin-left: 0.5rem;
}

.pin {
    background-color: transparent;
    border: none;
}

.pinIgnitionOn {
    color: color("pinIgnitionOn");
}

.pinIgnitionOff {
    color: color("pinIgnitionOff");
}

.table {
    background-color: color("white");
    width: 100%;
    margin-bottom: 1rem;

    th {
        background-color: #F6F6F6;
        opacity: 0.8;
        font-size: 0.8rem;
        letter-spacing: 0px;
        color: #ACACAC;
    }

    td {
        padding: 0 0.8rem;
        border: 1px solid #EEEEEE;
        font-size: 0.8rem;
        border-radius: 2px 2px 0px 0px;
    }
}

.tootip {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px;
    background-color: #b93838 !important;
}

.flexWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.totalContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.totalText {
    font-weight: bold;
    color: #d5d5d5;
    font-style: italic;
}

.toggleContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggleLabel {
    margin-right: 0.5rem;
}

.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 2.4rem;
    height: 1.4rem;
    cursor: pointer;
}
  .toggleSwitch input[type="checkbox"] {
    display: none;
  }
  .toggleSwitch .switch {
    position: absolute;
    border: 1px solid color('gallery');
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    background-color: color('gallery');
  }
  .toggleSwitch .switch::before {
    position: absolute;
    content: "";
    left: 1px;
    top: 2px;
    width: 1rem;
    height: 1rem;
    border: 1px solid color('gallery');
    border-radius: 50%;
    transition: transform 0.3s ease;
    background-color: color('white');
  }
  .toggleSwitch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(1rem);
    background-color: color('white');
  }
  .toggleSwitch input[type="checkbox"]:checked + .switch {
    background-color: color('primaryDark');
  }
