@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.badge {
    border: 0;
    outline: 0;
    color: color('white');
    display: inline-block;
    margin-left: 1rem;
    margin-bottom: 0.3rem;
    padding: 0.3rem 0.7rem;
    border-radius: 5rem;
    
    
    .title {
        font-style: italic;
    }

    .value {
        font-style: italic;
        word-break: keep-all;
    }

    .removeButton {
        margin-left: 0.5rem;
        cursor: pointer;
    }
}

.primaryLight {
    color: color('white');
    background-color: color('primaryLight');
}

.primaryLightInverted {
    background-color: color('polar');
    color: color('success');
}

.primaryDark {
    color: color('primaryDark');
    background-color: color('primaryDarkOpacity');
}

.inactive {
    color: color('gray');
    background-color: color('lightGrayishBlue');
}
