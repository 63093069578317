@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.list {
    width: 100%;
    height: 100%;
}

.tabHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.counter {
    border-radius: 50%;
    background-color: color("primaryDark");
    color: color("white");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight("bold");
    left: 2px;
    top: -13px;
    position: relative;

    .counterNumber {
        padding-top: 2px;
    }
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fontBold {
    font-weight: bold;
}

.imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}

.labelSmall {
    font-size: 0.75rem;
    @include fontWeight("bold");
}

.spanCounter {
    font-size: 2.5rem;
    font-weight: bold;
    color: color("white");

    @include respond-below(xl) {
        font-size: 1.75rem;
    }

    @include respond-below(lg) {
        font-size: 1.55rem;
    }

    .spanSuffixCustomize {
        font-size: 1.85rem;

        @include respond-below(xl) {
            font-size: 1.25rem;
        }

        @include respond-below(lg) {
            font-size: 1.15rem;
        }
    }

    .countersContainer {
        display: flex;
        padding-bottom: 0.5rem;

        .counterBadge {
            margin-right: 0.2rem;
        }
    }

    .stopContainer {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .iconHeader {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .iconStop {
        @extend .iconHeader;
        background-image: url("../../../../../../assets/img/stop.png");
        width: 2rem;
        height: 2rem;
    }
}