@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2rem;
}
