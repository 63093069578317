@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.tabHeader {
    margin: 1rem 0;
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 0.875rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.75rem;
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}