@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.content {
    margin-bottom: 2rem;
}

.title{
    display: flex;
    justify-content: space-between;
    font-size: 1.05rem;
    @include fontWeight('bold');
    padding: 0.5rem 1rem;
    background-color: color("grey");
    border: 1px solid color("gallery");
}

.addIcon {
    padding-left: 1rem;
    cursor: pointer;
}

.status{
    font-weight: 500;
    font-style: italic;
    font-size: .85rem;
}