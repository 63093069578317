@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";
@import "../../../styles/utils/responsive.scss";


.badge {
    border: 0;
    outline: 0;
    color: color('white');
    display: inline-block;

    &.sizeNormal {
        padding: 0.1rem 0.875rem;
        font-size: 0.875rem;
        border-radius: 25px;
    }

    &.sizeRound {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 0.85rem;
        border-radius: 100%;
        min-width: 2rem;
        min-height: 2rem;
        
        border: 3px solid white;
        margin-left: 0.2rem;
        
        @include respond-below(xl) {
            min-width: 1.6rem;
            min-height: 1.6rem;
        }

        &.sizeRoundSmall {
            margin-left: 0;
            min-width: 1.7rem !important;
            min-height: 1.7rem !important;
        
            @include respond-below(xl) {
                min-width: 1.3rem !important;
                min-height: 1.3rem !important;
            }
        }
    }
}


.primaryLight {
    color: color('white');
    background-color: color('primaryLight');
}

.primaryLightInverted {
    background-color: color('polar');
    color: color('success');
}

.primaryDark {
    color: color('white');
    background-color: color('primaryDark');
}

.primaryDark10{
    color: color('primaryDark');
    background-color: color('primaryDark10');
}

.danger {
    color: color('white');
    background-color: color('danger');
}

.success {
    color: color('white');
    background-color: color('success');
}

.warning {
    color: color('white');
    background-color: color('warning');
}

.inactive {
    color: color('gray');
    background-color: color('lightGrayishBlue');
}

.default {
    color: color('white');
    background-color: color('frenchGray');
}

.waitingStatus {
    color: color('white');
    background-color: color('waitingStatus');
}

.greenAmerica {
    color: color('white');
    background-color: color('greenAmerica');
}

.yellowAmerica {
    color: color('white');
    background-color: color('yellowAmerica');
}

.outline{
    outline: 1px solid red;
}