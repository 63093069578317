.div{
    text-align: center;

    .title{
      margin-top: 1.5rem;
      font-size: 1.3rem;
      font-weight: bold;
      text-align:left;
    }

    .text{
      margin-top:1rem;
      text-align:justify;
    }
  }