@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.bold {
    @include fontWeight("bold");
}

.formContent {
    width: 100%;
    padding: 1rem 2rem;
    max-width: 40vw;

    @include respond-below(lg) {
        max-width: 45vw;
    }

    @include respond-below(md) {
        max-width: 50vw;
    }

    @include respond-below(sm) {
        max-width: 65vw;
    }
}

.buttonsFooter {
    text-align: right;
}

.dateRangeCustom {
    display: inline-flex;
    justify-content: space-between;
}

.situationDetails{
    display: flex;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    border: 1px solid #EAEAEA;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    width: 100%;

    @include respond-below(lg) {
        font-size: 0.8rem;
    }

    .labelContainer{ 
        text-align: center;
        height: 100%;
        
        .selected{   
            color: color('white');
            background-color: color('primaryDark');
            padding: 0.5rem 1rem;
            word-break: keep-all;   
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond-below(lg) {
                padding: 0.7rem 1rem;
            }
        }

        .notSelected{
            color: color('black');
            background-color: color('mercury');
            padding: 0.5rem 1rem;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .labelContent {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}