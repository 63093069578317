@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";

.vertical-timeline {
    padding: 0 !important;
    margin: 0 !important;
}
.vertical-timeline::before{
    left: 4px !important;
    top: unset !important;
    width: 3px !important;    
    background: color('frenchGray') !important;
}

.vertical-timeline-element{
    margin: 0 !important;
}

.vertical-timeline-element-content {
    box-shadow: none !important;
    top: -9px !important;
    padding: 0 0.5rem !important;
    margin-left: 1rem !important;
    background: none !important;
}

.vertical-timeline-element-icon{
    width: 11px !important;
    height: 11px !important;
    margin-left: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: color('black');
}

.vertical-timeline-element-content-arrow{
    display: none;
}

