@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.fullWidth {
     width: 100% !important;
}
.rangeContainer {
     display: flex;
     flex-direction: row;
}
