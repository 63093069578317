@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.textTitle {
    font-size: 20px;
    @include fontWeight('bold');
    margin-bottom: 10px;
    color: color('primaryDark');
}

.messageContainer {
    margin-bottom: 20px;

    .textMessage {
        font-size: 16px;
    }
}

.buttonsContainer {
    margin: 1rem 0 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include respond-below(lg) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .button{
        @include respond-below(lg) {
           margin-bottom: 0.5rem;
        }
    }
}