@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.box {
    width: 100%;
    margin-bottom: 2rem;
    padding: 0;

    &.noPadding {
        padding: 0;
    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
    left: 2px;
    top: -13px;
    position: relative;
  }

  .counterNumber{
      padding-top: 2px;
  }


.rowCounters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
    }

    .boxCounterContainer {
        width: 32.5%;
        background: linear-gradient(180deg, color("primaryLight") 20%, color("primaryDark") 100%);
        border-radius: 0.5rem;
        @include respond-below(md) {
            width: 100%;
            margin-bottom: 0.25rem;
        }

        .boxCounterContent {
            display: inline-flex;
            align-items: center;
            width: 100%;

            .img {
                margin-right: 1rem;
                height: 2.5rem;
                width: 2.5rem;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                @include respond-below(xl) {
                    height: 2rem;
                    width: 2rem;
                }
            }

            .labelCounterContainer {
                display: inline-flex;
                justify-content: space-between;
                align-items: flex-end;
                flex: 1;
                
                @include respond-below(xxl) {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                
                @include respond-below(md) {
                    display: inline-flex;
                    flex-direction: inherit;
                    justify-content: space-between;
                    align-items: flex-end;
                    flex: 1;
                }

                .spanLabel {
                    font-size: 1.4rem;
                    font-weight: 300;
                    line-height: 1.75rem;
                    color: color("white");
                    
                    // @include respond-above(xl) {
                    //     max-width: 50%;
                    // }

                    @include respond-above(xxl) {
                        max-width: 42%;
                    }

                    @include respond-above(xxxl) {
                        max-width: 38%;
                    }

                    @include respond-below(xl) {
                        font-size: 1.15rem;
                    }

                    @include respond-below(lg) {
                        font-size: 1rem;
                    }
                }

                .spanCounter {
                    font-size: 2.5rem;
                    font-weight: bold;
                    color: color("white");

                    @include respond-below(xl) {
                        font-size: 1.75rem;
                    }

                    @include respond-below(lg) {
                        font-size: 1.55rem;
                    }

                    .spanSuffixCustomize{
                        font-size: 1.85rem;

                        @include respond-below(xl) {
                            font-size: 1.25rem;
                        }
    
                        @include respond-below(lg) {
                            font-size: 1.15rem;
                        }
                    }
                }
            }
        }
    }
}