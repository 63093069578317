@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.editorContent {
    height: 100%;
}

.canvasContainer {
    text-align: center;
    position: relative;
    cursor: crosshair;
}

.buttonsContainer {
    margin-top: 1em;
    text-align: right;
}
