@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.mainBox {
    padding: 2rem;
}

.headerBoxWarning {
    background-color: rgb(226, 180, 52);
    padding: 1rem;
    margin: 1rem 0;
}

.bold {
    font-weight: bold;
    font-size: 0.9rem;
}

.companyRow {
    margin-bottom: 20px;
    border-bottom: solid 1px #ededed;

    .companyLogoCol {
        text-align: center;

        .companyLogo {
            height: var(--max-height);
            max-height: 100px;
        }
    }
}

.packsCol {
    padding-top: 1rem;
}

.resumeCol {
    padding-top: 1rem;
}

.resumeBox {
    width: 100%;
    background: linear-gradient(180deg, #16e0cc 0%, #1781a1 100%);
    color: #ffffff;
    border-radius: 1.5rem;
    padding: 20px 20px !important;
    margin-bottom: 1rem;

    .colTitle {
        text-align: center;
    }

    .labelTitle {
        font-weight: bold;
        font-size: 1rem;
        padding-left: 10px;
        color: white !important;
    }

    .label {
        font-weight: bold;
        font-size: 1.2rem;
        padding-left: 10px;
        color: white !important;
        white-space: nowrap;
    }

    .resumeTitle {
        margin-bottom: 1.5rem;
    }

    .resumeRow {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .alignRight {
        text-align: right;
    }
}

.box {
    display: inline-flex;
    flex: 1;
    width: 100%;
    align-items: center;
    border: 1px #eeeeee solid;
    border-radius: 20px;
    justify-content: space-between;
    padding: 20px 20px !important;
    margin-bottom: 1rem;

    .textContainer {
        display: inline-flex;
        align-items: center;
    }

    .addIcon {
        height: auto;
        width: 60px;
    }

    .text {
        font-weight: bold;
        font-size: 25px;
        padding-left: 10px;
    }

    .planContainer {
        display: flex;
        flex-direction: column;

        .label {
            font-size: 12px;
        }

        .title {
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    .validMonthsMessage {
        margin-bottom: 5px;
    }
}

.boxExpired {
    opacity: 0.5;
}

.plan {
    font-weight: bold;
    font-size: 25px;
}

.alignCenter {
    text-align: center;
}

.containerCards {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    flex-wrap: wrap;
}
