@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";


.box {
    margin-top: 1rem
}

.counterList {
    border-radius: 50%;
    background-color: color('primaryDark');
    color: color('white');
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight('bold');
    left: 2px;
    top: -13px;
    position: relative;
}

.counterNumber {
    padding-top: 2px;
}

