@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss"; 

.containerWrapper{
  padding: 1rem 1rem 2rem 1rem;

  .contentManagement{
    margin-bottom: 1rem;
  }

  .hideTab{
      display: none;
  }

  .formContent{
    margin-top: 1.25rem;
  }

  .formContainer{
      margin: 1rem 0 ;
  }
}

.form {
  padding-top:1rem;
  
  .title{
    padding: 0rem 1rem;
  }

  .label {
      font-weight: bold;
      font-size: 0.9rem;
  }

  .buttonContainer {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      padding:1rem;
  }
}

