@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container { 
    padding: 1rem 0rem 1rem 1rem;
    max-width: 25rem;
    cursor: pointer;

    &.innerContainer{
        padding: 1rem 0rem 1rem 0rem;
    }

    // &.isOpen {
    //     border-bottom: 1px solid color('mercury');
    // }
} 

.description {
    margin-right: 0.5rem;
    font-weight: 600;
}

.selectContainer{
    display: flex;
}
