@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.box{
    .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }
        
        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;

            @include respond-below(lg) {
                font-size: 0.7rem;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }
}

.label{
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}