@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.eco {
    padding: 2px 5px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: fit-content;
    white-space: nowrap;

    &.eco_success {
        border: 1px solid #D8E4D3;
        background-color: #E7F2E2;
        color: #73C25C;
    }

    &.eco_danger {
        border: 1px solid #F0C9C9;
        background-color: #FFDEDE;
        color: #D22222;
    }

    &.eco_warning {
        border: 1px solid #E6DDBC;
        background-color: #FFF8E0;
        color: #F3A70D;
    }
}

.table {
    background-color: color("white");
    width: 100%;
    margin-bottom: 1rem;

    tr {
        border: 1px solid #EEEEEE;
    }

    th {
        background-color: #F6F6F6;
        opacity: 0.8;
        font-size: 0.8rem;
        letter-spacing: 0px;
        color: #ACACAC;
        text-align: left;
        padding-left: 10px;
    }

    td {
        padding: 0 0.8rem;
        font-size: 0.8rem;
        text-align: left;
        padding-left: 10px;
    }
    
    .row_eco_success {
        background-color: #E7F2E2;
    }
    .row_eco_warning {
        background-color: #FFF8E0;
    }
    .row_eco_danger {
        background-color: #FFDEDE;
    }
}

.tooltip {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px;
    background-color: #b93838 !important;
}

.p08 {
    padding: 0.8rem;
}