@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";

.content{
    padding: 1rem;

    @include respond-below(lg) {
        margin: 1rem;
    }

    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');

        &.labelQuestion{
            font-size: 1rem;
        }
    }
    .errorLabel{
        display: block;
        @include fontWeight('light');
        font-size: 14px;
        margin-bottom: 4px;
        color: color('danger');
    }

    .header {
        font-size: 1.5rem;
        @include fontWeight('bold');
        margin-bottom: 2rem;
    
        .icon{
            cursor: pointer;
            width: 1.0625rem;
            margin-right: 0.3rem;
            transform:  rotate(-180deg);
            filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
            opacity:1
        }
        
        .messageDeleted{
            font-style: italic;
            @include fontWeight('normal');
            color: color('danger');
            font-size: 1.1rem;
            margin-bottom: 2rem;
        }
    }

    .colXXL{
        @include respond-above(xxl) {
            flex-basis: 33.33333333% !important;
            max-width: 33.33333333% !important;
        } 
    }
}


.buttonsContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }

    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}


.colRightContent{
    padding: 1rem;
    background-color: color('grey');
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .subtitle{
        color: color('grayAmerica');
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }
}

.fullHeight{
    height: 100%;
}

.containerSmallButton{
    display: inline-flex;
    align-items: center;
    
    .iconAdd{
        width: 1rem;
    }
}

.rowAlerts{
    margin-bottom: 0.1rem;
}

.removeIcon {
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    fill: color('white');
    cursor: pointer;
}

.noInfoText {
    font-size: 0.9rem;
}

.buttonOptions {
    font-size: 0.95rem;
    color: color("primary");
    opacity: 1;
    margin-bottom: 2px;
}