@import ".../../../../../../styles/utils/mixins.scss";

.filtersLabel{
    font-size: 0.875rem!important;
    @include fontWeight('bold');
}

.searchButton{
    display: flex;
    align-items: center;
    justify-content: end;
}


.bold {
    @include fontWeight("bold");
}