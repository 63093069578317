@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";
@import "../../../styles/utils/responsive.scss";


.boxCounterContainer {
    width: 32.5%;

    &.col4 {
        width: 24.5% !important;

        @include respond-below(lg) {
            width: 49.5% !important;
            margin-bottom: 0.25rem;
            margin-left: 0.1rem;
        }

        @include respond-below(md) {
            width: 100% !important;
            margin-bottom: 0.25rem;
        }
    }
    background: transparent linear-gradient(180deg, color("primaryLight") 0%, color("greenDarker") 100%) 0% 0% no-repeat padding-box;
    //background: linear-gradient(180deg, color("primaryLight") 20%, color("primaryDark") 100%);
    border-radius: 0.5rem;
    padding: 1rem;
    @include respond-below(md) {
        width: 100%;
        margin-bottom: 0.25rem;
    }

    .boxCounterContent {
        display: inline-flex;
        align-items: center;
        width: 100%;

        .img {
            margin-right: 1rem;
            height: 1.75rem;
            width: 2rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include respond-below(xl) {
                height: 1.5rem;
                width: 1.75rem;
            }
        }

        .labelCounterContainer {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;

            @include respond-below(xxl) {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
            }
            
            @include respond-below(xl) {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
            }
            
            @include respond-below(md) {
                display: inline-flex;
                flex-direction: inherit;
                justify-content: space-between;
                align-items: flex-end;
                flex: 1;
            }

            .spanLabel {
                font-size: 1.3rem;
                font-weight: 300;
                line-height: 1.75rem;
                color: color("white");
                max-width: 160px;

                @include respond-above(xxxl) {
                    word-break: keep-all;
                } 
                        
                @include respond-below(xxl) {
                    max-width: 100%;
                } 

                @include respond-below(xl) {
                    font-size: 1.2rem;
                }
                @include respond-below(lg) {
                    font-size: 1rem;
                }

                @include respond-below(md) {
                    font-size: 1rem;
                }

                &.spanMoreWidth{
                    justify-content: space-between;
                    align-items: flex-end;

                    @include respond-below(xxxxl) {
                        max-width: 80% !important;
                    }
                    @include respond-below(xl) {
                        max-width: 100% !important;
                    }
                }

                .spanSubLabel{
                    font-size: 0.9rem;
                    text-transform: lowercase;

                    @include respond-below(lg) {
                        font-size: 0.8rem;
                    }
                }
            }

            .spanCounter {
                font-size: 2rem;
                font-weight: bold;
                color: color("white");

                @include respond-below(xxl) {
                    font-size: 1.7rem;
                }

                @include respond-below(xl) {
                    font-size: 1.75rem;
                }

                @include respond-below(lg) {
                    font-size: 1.55rem;
                }

                .spanSuffixCustomize{
                    font-size: 1.85rem;

                    @include respond-below(xl) {
                        font-size: 1.25rem;
                    }

                    @include respond-below(lg) {
                        font-size: 1.15rem;
                    }
                }
                
                .countersContainer{
                    padding-bottom: 0.5rem;
                    display: flex;
                    flex-direction: row;

                    .counterBadge{
                        margin-right: 0.1rem;
                        min-width: 2rem;
                        min-height: 2rem;

                        @include respond-below(xxl) {
                            min-width: 1.6rem;
                            min-height: 1.6rem;
                        }
                    }
                }
                
            }
        }
    }
}
