@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.headerBox {
    margin-bottom: 2rem;
    padding: 1rem;    
}

.headerBoxWarning {    
    background-color: rgb(226, 180, 52);
}

.colorTextWarning {    
    color: color("black") !important;
}

.col {
    position: relative;
    padding-left: 2%;
    padding-right: 2%;

    @media (min-width: 1300px) {
        padding-left: 3%;
        padding-right: 3%;
    }

    @media (min-width: 1400px) {
        padding-left: 4%;
        padding-right: 4%;
    }

    @media (min-width: 1600px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.no-padd {
    position: relative;
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.borderRight {
    border-right: 2px solid color("mercury");

    @include respond-below(md) {
        border-right: none;
    }
}

.borderLeft {
    border-left: 2px solid color("mercury");
    @include respond-below(md) {
        border-left: none;
    }
}

.cont {
    height: 100%;
    width: 100%;

    .content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .info {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.1rem;

            @include respond-below(lg) {
                margin-right: 0;
            }

            .label {
                align-items: center;
                display: flex;
                flex-direction: row;
                font-size: 0.875rem;
                word-break: keep-all;
                //color: color("gray");

                @include respond-below(lg) {
                    font-size: 0.7rem;
                }
            }

            .text {
                margin-left: 0.5rem;
                font-size: 1rem;
                @include fontWeight("bold");
                flex: 1;
                text-align: right;
                word-break: keep-all;
                white-space: nowrap;

                @include respond-below(lg) {
                    font-size: 0.7rem;
                    padding-right: 0.5rem;
                }

                .point {
                    width: 7px;
                    height: 7px;
                    margin-left: 0.5rem;
                    border-radius: 50%;
                    background-color: color("success");

                    @include respond-below(lg) {
                        width: 5px;
                        height: 5px;
                    }
                }
                .pointRed {
                    background-color: color("danger");
                }

                .pointGreen {
                    background-color: color("success");
                }
            }

            .subText {
                font-size: 0.875rem;
                color: color("gray");
                @include fontWeight("normal");

                @include respond-below(lg) {
                    font-size: 0.6rem;
                }
            }
        }
    }
}

.divDetailsCar {
    display: flex;
    height: 100%;

    .divImage {
        flex: 1;
        padding-right: 1rem;

        .img {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            min-width: 115px;
            min-height: 92px;

            @include respond-below(lg) {
                width: 100%;
            }
        }
    }

    .divDesc {
        display: flex;
        align-items: center;

        .labelRegistration {
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            @include fontWeight("bold");

            @include respond-below(lg) {
                font-size: 1rem;
            }
        }

        .labelDetails, .labelLocal {
            font-size: 1rem;
            word-break: keep-all;
            

            @include respond-below(lg) {
                font-size: 0.7rem;
            }
        }

        .labelDetailsColor{
            color: color("gray");
        }

        .labelLocal {
            font-weight: bold;
            color: color("black");
        }
    }

    .button {
        word-break: keep-all;
    }
}

.divPoint {
    position: relative;
    .point {
        position: absolute;
        right: -15px;

        @include respond-below(lg) {
            right: -10px;
        }

        @include respond-below(md) {
            right: -5px;
        }
    }
}

.ignorePadding{
    padding: 0 !important;
}

.itemText {
    position: relative;

    .floatingBadge {
        top: 0;
        right: -.65rem;
        transform: translate(80%, -45%);
    }
}

.container{
    padding: 1rem;

    .tabHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rowFlex{
            display: flex;

            .filtersPopoverContainer{
                margin-top: 1rem;
            }
        }
    }
}

.tour{
    min-width: 30rem!important;
}

.overlay {
    position: absolute;
    top: 50%;
    background-color: white;
    border: solid 7px color("danger");
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    @media (max-width: 2600px) {
        left: 25%;
    }

    @media (max-width: 2000px) {
        left: 18%;
    }

    @media (max-width: 1600px) {
        left: 15%;
    }

    @media (max-width: 1400px) {
        left: 25%;
    }
}

.overlayText {
    color: color("danger");
    font-weight: bold;
    text-transform: uppercase;

    @include respond-below(lg) {
        font-size: smaller
    }
}

.opacity {
    opacity: 0.4;
    box-shadow: 0px 5px 10px darkgray;
}

.opacityBackground {
    background-color: #cccccc;
}