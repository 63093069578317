@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";

.container{
  border: 1px solid color('gallery');
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 5.3rem;
}

.label{
  color: color('primaryDark');
  font-size: 12px;
  width: 100%;
  cursor: pointer;
}

.qrCode{
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
}

.closeButtonModal{
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.messageContainer{
  display: flex;
  justify-content: center;
  padding: 1rem 0 2rem 0;

}