@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;       
}

.header {
    background-color: color('grey');
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .headerText {
        font-size: 1.125rem;
        @include fontWeight('bold');
    }

    .headerButton {
        cursor: pointer;

        .headerButtonIcon {
            width: 21px;
            height: 21px;
        }
    }
}
.content {
    margin-top: 1rem;
    border: 2px solid color('grey');
    border-radius: 0.5rem;
}

.noInfoText {
    text-align: center;
    padding: 1rem;
    border: 1px solid color('gallery');
    border-top: 0;
    border-radius: 0px 0px 10px 10px;
}