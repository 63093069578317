@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";
@import "../../../styles/utils/responsive.scss";

.sortingDropdown {
    position: relative;
}

.content {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px 0;
    // background-color: #EAEAEA;
    // border: 1px solid #DFDFDF;
    // border-radius: 4px;
}

.options {
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 5px;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    left: -125px;
    top: calc(100% + 5px);
    width: max-content;
}

.option {
    font-size: 14px;
    padding: 8px;
}

.selected {
    color: #A3A3A3;
}