.container{
    padding: 1rem;

    .pageNameContainer {
        display: flex;
        align-items: center;
        flex-direction: row;

        .pageNameContent {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            cursor: pointer;

            .pageNameContentImage {
                cursor: pointer;
                width: 1.0625rem;
                margin-right: 0.3rem;
                transform:  rotate(-180deg);
                filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
                opacity:1
            }
        
            .pageNameContentText {
                margin-left: 0.5rem;
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }

    .usernameCol{
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.25rem;
        margin-left: 0.5rem;
    }
}

.form {
    margin-top: 1.5rem;

    .label {
        font-weight: bold;
        font-size: 0.9rem;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }

    .roles {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }    
    
    
}

.avatarContainer{
    display: flex;

    .avatarChooser {
        width: 7rem;
        height: 7rem;
        text-align: center;
    
        .avatarImage {
            margin-bottom: 1rem;
            width: 6rem;
        }
    
        .avatarFilesList {
            justify-content: center;
        }
    }
}
