@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.filters {
    width: 100%;
    padding-bottom: 1rem;

    .filtersLabel{
        font-size: 0.875rem!important;
        @include fontWeight('bold');
    }
    .searchButton{
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.divider{
    display: flex;
    border-top: 2px solid color("gallery");
    padding-bottom: 1.5rem;
}

.vehicleCell{
    display: flex;

    .imageCell {
        display: inline-flex;
        align-items: center;

        .contentImage {
            .img {
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
        
                @include respond-below(lg) {
                    width: 100%;
                }
            }
        }
    }
    .description {
        margin-left: 0.8rem;

        .bold {
            @include fontWeight("bold");
            font-size: 1.125rem;
        }
    }   

}

.smallLabel{
    font-size: 0.875rem;
}

.bold{
    @include fontWeight("bold");
}

.driverEmail{
    margin-left: 1.8rem;
    font-size: 0.875rem;
}

.reserveButton{
    margin-left: 0!important;
    font-size: 12px !important;
    padding: 0.2rem 1.2rem !important;
}

.alertMessage{
    display: flex;
    align-items: center;
    background-color: color('alabaster');
    padding: 0.5rem 1rem;
    margin:0 0.5rem 0.5rem 0;
    font-size: 0.875rem;

    .iconAlertMessage{
        margin-right: 0.5rem;
    }
}