@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.cardContainer {
    position: relative;
    width: 19.5%;
    margin: 0.25%;
    padding: 1rem 1rem 0.25rem 1rem;
    cursor: pointer;


    @include respond-below(xxxl) {
        width: 24.5%;
    }

    @include respond-below(xl) {
        width: 32.5%;
    }

    @include respond-below(md) {
        width: 49.5%;
    }

    @include respond-below(sm) {
        width: 100%;
        margin: 0.25% 0%;
    }

    .iconHeader {
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .iconNumber {
       z-index: 3;
    }

    .iconStop {
        @extend .iconHeader;
        background-image: url("../../../../assets/img/stop.png");
        top: 0.4rem;
        right: 0.5rem;
        width: 1.2rem;
        height: 1.2rem;
    }

    .containerBadge {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;

        .iconCalendar {
            font-size: 0.8rem;
        }

        .infoBadge {
            padding: 0.15rem 0.9rem !important;
            @include fontWeight("bold");
            font-size: 0.75rem;
            margin-top: 0.2rem;
            cursor: pointer;
        }
    }

    .cardContent {

       height: 100%;

        .contentImage {
            .img {
                height: 10rem;
                width: 100%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                @include respond-below(lg) {
                    height: 6rem;
                }
            }
        }

        .columnPlate {
            @include fontWeight("bold");
            font-size: 1.125rem;
            margin-top: 0.25rem;
        }

        .columnBrand,
        .columnSpec,
        .columnLocals {
            font-size: 0.875rem;
        }

        .columnLocals {
            font-weight: bold;
        }

        .columnDriver {
            height: 2.5rem;
            margin-top: 0.5rem;
            font-size: 0.875rem;
        }

        .bottomLabels {
            display: inline-flex;
            justify-content: space-between;
            align-items: flex-end;
            flex: 1;
            width: 100%;
            font-size: 0.875rem;

            @include respond-below(xxl) {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
            }

            @include respond-below(md) {
                display: inline-flex;
                flex-direction: inherit;
                justify-content: space-between;
                align-items: flex-end;
                flex: 1;
            }
        }
    }

    .fontBold {
        font-weight: bold;
    }


}

.opacity {
    opacity: 0.3;
}

.opacityBackground {
    background-color: #E6E6E6;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    border: solid 7px color("danger");
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.overlayText {
    color: color("danger");
    font-weight: 900;
    text-transform: uppercase;
}


.inputGroup {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
}

.position {
    color: #16BABB;
    .label {
        flex:1;
        display: block;
        border: 0;
        font-family: "Roboto", sans-serif;
        font-size: 0.875rem;
        padding: 5px;
    }
}

.topLeft {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
}
.cardContainerHeight{
    height: 100%;
}

.noGpsData {
    display: flex;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    padding: 0.3rem 0.4rem 0.2rem;
    background-color: white;
    border: 1px solid lightgrey;
    font-size: small;
}