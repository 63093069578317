@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.form {
    margin-top: 1.5rem;

    .label {
        font-weight: bold;
        font-size: 0.9rem;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }

    .roles {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }  
}


.usernameCol{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.25rem;
    margin-left: 0.5rem;
}

.avatarContainer{
    display: flex;

    .avatarChooser {
        width: 7rem;
        height: 7rem;
        text-align: center;
    
        .avatarImage {
            margin-bottom: 1rem;
            width: 6rem;
        }
    
        .avatarFilesList {
            justify-content: center;
        }
    }
} 








