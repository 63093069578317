@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";
@import "../../../styles/utils/responsive.scss";

.noEnoughInfoText {
	font-size: 1rem;
	padding: 1rem 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: auto;
	@include scrollbars();
	padding-right: 0.2rem;
}

.item {
	display: flex;
	flex-direction: row;
	margin: 0.6rem 0;
	cursor: pointer;
}

.itemRow {
	display: flex;
	flex-direction: row;
	margin-bottom: 0.3rem;
	align-items: center;
	margin-left: 0.3rem;
}

.itemText {
	font-size: 0.9rem;
	flex: 1;
	font-weight: bold;
	margin: 0;
	display: inline-block;
}

.itemVehicleRegistrationNumberText {
	font-size: 0.9rem;
	flex: 1;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.itemLeft {
	flex: 1;
	border-width: 1px;
}

.itemRight {
	display: flex;
	border-width: 1px;
	flex-direction: column;
}

.dateText {
	font-size: 0.9rem;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.contentData {
	width: 100%;
}