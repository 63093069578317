@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.messageContainer {
    .message {
        @include fontWeight('bold');
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
    }

    .annotation {
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
    }
}

.table {
    border-collapse:separate; 
    border-spacing: 0 1rem
}

.imageDescCell {
    display: inline-flex;
    align-items: center;

    .descCell {
        margin-left: 0.8rem;
    }
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}

.columnPlate {
    @include fontWeight("bold");
    font-size: 1.125rem;
}

.columnBrand{
    font-size: 0.875rem;
}

.dateCreated{
    margin-left: 1.8rem;
    font-size: 0.875rem;
}
.bold{
    font-weight: bold;
}
.typeDescription{
    font-style: italic;
    font-size: 0.875rem;
}
.dateReaded{
    display: flex;
    font-size: 0.875rem;
}

.statusColumn{
    display: flex;
    font-weight: bold;
    font-style: italic;
}