@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";


// .box {
//     width: 100%;
//     margin-bottom: 2rem;
//     padding: 0;

//     &.noPadding {
//         padding: 0;
//     }
// }

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include respond-below(sm){
        flex-direction: column-reverse;
    }

    .title{
        font-size: 2rem ;
        text-align: center;

        @include respond-below(sm){
            margin-top: 1rem;
        }
    }
}

.steps{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .step{
        width: 50%;
    }

    .active_step{
        hr{
            border: 5px color('primaryLight') solid;
        }

        .label{
            color:color('primaryLight');
        }
    }

    .label{
        font-size: 14px;
    }
}

.alignRight {
    align-self: flex-end;
}

hr{
    border: 5px #E3E3E3 solid;
    border-radius: 5px;
}

.buttons_container{
    display: flex;
    justify-content: flex-end;

    @include respond-below(xs){
        flex-direction: column-reverse;
    }

    .button{
        @include respond-below(sm){
            flex:1;
            margin-top:1rem;
        }
    }
}

.infoMessage{
    display: flex;
    align-items: center;
    background-color: color('water');
    color: color('primaryDark');
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: bold;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
}