@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.inputGroup {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
    background-color: color('white');
}

.inputGroupAppend {
    margin-left: -1px;
    display: flex;
    align-self: stretch;
}


.inputGroupText {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: color('black');
    text-align: center;
    white-space: nowrap;
    background-color: color('athensGray');
    @include fontWeight('bold');
    border: 1px solid color('mercury');
    border-radius: 0 25px 25px 0;
}

.fullWidth {
     width: 100% !important;
}