@import "../../../styles/utils/functions.scss";

.filtersList{
    text-align: end;
    color: color('primaryDark');
    font-size: 11px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}