@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.form {
    padding-bottom: 3rem;
}

.rootContainer{
    padding: 1rem;
}

.pageNameContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .pageNameContent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        cursor: pointer;

        .pageNameContentImage {
            cursor: pointer;
            width: 1.0625rem;
            margin-right: 0.3rem;
            transform:  rotate(-180deg);
            filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
            opacity:1
        }

        .pageNameContentText {
            margin-left: 0.5rem;
            font-size: 1.5rem;
            font-weight: bold;
        }
        .messageDeleted{
            font-style: italic;
            @include fontWeight('normal');
            color: color('danger');
            font-size: 1.1rem;
        }
    }
}

.verificationContainer {
    min-height: 300px;
    max-height: 500px;

    // .scroller {
    .containerRight {
        // flex: 0 0 50%;

        .containerRightTitle {
            padding: 0.5rem 1rem;
            color: #d3d3d3;
            font-size: 0.875rem;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
        }

        .containerRightContent {
            padding: 0.5rem 1rem;
            display: none;

            .containerRightContentLabel {
                text-align: left;
                font: normal normal bold 14px/17px Roboto;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-bottom: 1rem;
            }

            .containerRightContentInput {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                padding: 0.5rem 1rem;
                border: 1px solid #eaeaea;
                border-radius: 25px;
            }

            .containerRightContentTextArea {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                padding: 0.5rem 1rem;
                min-height: 100px;
                border: 1px solid #eaeaea;
                border-radius: 5px;
            }

            .containerRightContentDate {
                overflow: hidden;
                position: relative;

                .containerRightContentDateIcon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 25%;
                    background: #eaeaea;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    // }
}

.col {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.verificationInfos {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pageNameContentText {
    //margin: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .imagePreview {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 40px;
        height: 40px;
        margin: 0.2rem;
        cursor: pointer;
        
    }
}

.descriptionInfo {
    .descriptionInfoType {
        text-align: left;
        font: normal normal bold 16px/19px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 1rem;
    }

    .descriptionInfoZone {
        text-align: left;
        font: normal normal normal 16px/19px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .descriptionInfoDescrition {
        text-align: left;
        font: normal normal normal 16px/19px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
}

.restrained {
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
    @include scrollbars();
}

.scroll {
    overflow: auto;
    @include scrollbars();
}

.containerLeft {
    height: 85%;
    overflow: hidden;

    .tableList {
        height: 80%;
        overflow: auto;
        @include scrollbars();
    }

    .tablePagination {
        height: 20%;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;
}

.container {
    padding: 1rem;
    position: relative;
    @include elevation(4);

    &.containerError {
        @include elevation(4, #dc4c4c);
    }

    .containerRemove {
        display: none;
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }

    &:hover {
        .containerRemove {
            display: inline-flex;
        }
    }
}