@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.bold {
    @include fontWeight("bold");
}

.light {
    color: gray;
}

.break {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;

    @include respond-below("sm") {
        flex-basis: 100%;
        max-width: 100%;
    }

    @include respond-above("sm") {
        flex-basis: 50%;
        max-width: 50%;
    }
}
