@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";
@import "../../../../../styles/theme/variables.scss";

.box{
    width: 100%;
    margin-bottom: 2rem;

    .header {
        font-size: 1.5rem;
        @include fontWeight('bold');
        margin-bottom: 2rem;

        .icon{
            cursor: pointer;
            width: 1.5rem;
            margin-right: 1rem;
            transform:  rotate(-180deg);
        }
    }

    .content{
        display: flex;
        flex-direction: row;
        margin: 1rem;

        @include respond-below(lg) {
            margin: 1rem;
        }
    }

    .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }
        
        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;

            @include respond-below(lg) {
                font-size: 0.7rem;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }
}

.content{
    padding: 1rem;
    .header {
        font-size: 1.2rem;
        @include fontWeight('bold');
        margin-bottom: 2rem;

        .icon{
            cursor: pointer;
            width: 1.0625rem;
            margin-right: 0.3rem;
            transform:  rotate(-180deg);
            filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
            opacity:1
        }
    }
}

.label{
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}

.buttonsContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }
    
    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}

.checkboxArea{
    padding: 0.5rem 0;
}

.addIcon{
    padding: 0 1rem 0 0;
}

.fileArea{
    align-items: center;
    display: flex;
    justify-content: center;
}

.groupRefundTreated{
    display: flex;
}

.groupSpinner{
    width: 50%;
}

.groupInputRefundTreated{
    width: 50%;
}

.spinner{
    display: flex;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;

    .labelSpinner{ 
        text-align: center;
        .selected{   
            color: color('white');
            background-color: color('primaryDark');
            padding: 0.7rem 1rem;
        }

        .notSelected{
            color: color('black');
            background-color: color('white');
            padding: 0.7rem 1rem;
        }
    }
}

.spinnerDetails{
    display: flex;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAEAEA;
    border-radius: 25px;
    overflow: hidden;

    .labelSpinner{ 
        text-align: center;
        .selected{   
            color: color('white');
            background-color: color('primaryDark');
            padding: 0.7rem 1rem;
        }

        .notSelected{
            color: color('black');
            background-color: color('white');
            padding: 0.7rem 1rem;
            background-color: #E8E8E8;
        }
    }
}

.colRightContent{
    padding: 1rem;
    background-color: color('grey');

    .subtitle{
        color: color('grayAmerica');
        @include fontWeight('bold');
        font-size: 1.2rem;
        margin: 0.5rem 0 1rem 0;
    }

    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }

    .scrollable {
        max-height: 45rem;
        min-height: fit-content;
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbars();
        padding: 0 0.2rem;
    }
  
    .situation{
        display: flex;
        @include fontWeight('light');
        @include placeholderColor(color('frenchGray'));
        border: 1px solid #EAEAEA;
        border-radius: 25px;
        cursor: pointer;
        overflow: hidden;
        width: 100%;

        @include respond-below(lg) {
            font-size: 0.8rem;
        }

        .labelContainer{ 
            text-align: center;
            height: 100%;
            
            .selected{   
                color: color('white');
                background-color: color('primaryDark');
                padding: 0.5rem 1rem;
                word-break: keep-all;  
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;

                @include respond-below(lg) {
                    padding: 0.7rem 1rem;
                }
            }

            .notSelected{
                color: color('black');
                background-color: color('white');
                padding: 0.5rem 1rem;
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .labelContent {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

   
    .situationDetails{
        display: flex;
        @include fontWeight('light');
        @include placeholderColor(color('frenchGray'));
        border: 1px solid #EAEAEA;
        border-radius: 25px;
        cursor: not-allowed;
        overflow: hidden;
        width: 100%;

        @include respond-below(lg) {
            font-size: 0.8rem;
        }

        .labelContainer{ 
            text-align: center;
            height: 100%;
            
            .selected{   
                color: color('white');
                background-color: color('primaryDark');
                padding: 0.5rem 1rem;
                word-break: keep-all;   
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;

                @include respond-below(lg) {
                    padding: 0.7rem 1rem;
                }
            }

            .notSelected{
                color: color('black');
                background-color: color('mercury');
                padding: 0.5rem 1rem;
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .labelContent {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.fullHeight{
    height: 100%;
}

.labelHistory{
    font-size: 0.875rem;
    @include fontWeight('bold');
}

.messageRefundApproved{
    display: flex;
    width: 100%;
    background-color: color('buttercup');
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin:0 0.5rem 1rem 0;
    align-items: center;

    .labelRefundApproved{
        width: 100%;
        text-align: start;
        @include fontWeight('bold');
        color: color('toastedYellow');
        font-size: 14px;
    }
}

.messageDeleted{
    font-style: italic;
    @include fontWeight('normal');
    color: color('danger');
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

.divider{
    display: flex;
    border-top: 2px solid color("gallery");
    padding-bottom: 1rem;
    margin-left:0.2rem ;
}

.filesHeader{
    display: flex;
    flex:1 ;
    border-radius: 5px;
    border: 1px solid color('mercury');
    background-color: color('grey');
    padding:0 1rem;
    margin-bottom: 0.5rem;
}

.filesContent{
    display: flex;
    border-radius: 5px;
    border: 1px solid color('mercury');
    padding: 0.5rem;
}