@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";

.fullHeight{
    height: 100%;

    .header {
        font-size: 1.2rem;
        @include fontWeight("bold");
        margin-bottom: 2rem;        
    
        .icon {
            cursor: pointer;
            width: 1.0625rem;
            margin-right: 0.3rem;
            transform:  rotate(-180deg);
            filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
            opacity:1
        }
    }
}

.buttonsContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }

    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}

.checkboxArea {
    padding: 0.5rem 0;
}

.groupSpinner {
    // width: 70%;
}

.spinner {
    display: flex;
    @include fontWeight("light");
    @include placeholderColor(color("frenchGray"));
    font-size: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;

    .labelSpinner {
        text-align: center;
    }
    .selected {
        color: color("white");
        padding: 0.7rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .notSelected {
        color: color("black");
        background-color: color("white");
        padding: 0.7rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.spinnerDetails {
    display: flex;
    @include fontWeight("light");
    @include placeholderColor(color("frenchGray"));
    font-size: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 25px;
    overflow: hidden;

    .labelSpinner {
        text-align: center;
    }
    .selected {
        color: color("white");
        background-color: color("success");
        padding: 0.7rem 1rem; 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .notSelected {
        color: color("black");
        background-color: color("white");
        padding: 0.7rem 1rem;
        background-color: #e8e8e8; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.label{
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}

.colLeftContent{
    padding: 1rem;
}
.colRightContent{
    padding: 1rem;
    background-color: color('grey');
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .subtitle{
        color: color('grayAmerica');
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .label{
        font-size: 0.9rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }
}