@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.icon{    
    color:color('primary');
    cursor: pointer;
    font-size: 20px;
}

.dropdownContainer {
    position: absolute;
    right: -15px;
    margin-top: 5px;
    background-color: color('white');
    border-radius: 4px;
    border: 1px solid color('athensGray');
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: keep-all;  
    z-index: 1; 
    box-shadow: 0px 2px 10px #0000001a;
}

.dropdownItem {
    font-size: 0.9rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    padding: 14px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: color('lightblack');

    &:hover {
        background-color: darken(color('white'), 3.5);
    }
}
