@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container{

    padding: 1rem;

    .header {
        font-size: 1.5rem;
        @include fontWeight('bold');
        margin-bottom: 2rem;
    
        .icon{
            cursor: pointer;
            width: 1.0625rem;
            margin-right: 0.3rem;
            transform:  rotate(-180deg);
            filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
            opacity:1
        }
    }
}
