@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.deletedCircle {
    width: 8px;
    height: 8px;
    background-color: color('danger');
    display: inline-block;
    border-radius: 50%;
    margin-left: 0.4rem;
    margin-bottom: 1px;
}
