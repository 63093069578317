@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
    display: inline-flex;
    justify-content: center;
    padding: 0 0.2rem;
    @include fontWeight('light');
    font-size: 0.75rem;
    margin-bottom: 4px;
    color: color('primaryDark');
    margin-top: 0.2rem;

    img {
        width: 1rem;
        height:auto;
        margin-right: 0.2rem;
        color: color('warning') !important;
    }

    .message{
        margin-left: 0.2rem;
    }
}

.messageWarning{
    color: color('warning') !important;
    font-size: 0.75rem;
    
}
