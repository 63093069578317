@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.container {
    position: relative;
    padding: 1rem;
}

.map {
    display: flex;
    flex:1;
    height: 100%;
    min-height: 70dvh;
    max-height: 90dvh;
}

.currentPin {
    padding: 5px;
    color: #16BABB;
}

.pr {
    padding-right: 0.2rem;
}

.pb {
    padding-bottom: 1.2rem;
}

.mr {
    margin-right: 0.2rem;
}

.inputGroup {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin-bottom: 1rem !important;
    .input {
        flex:1;
        padding-left: 5px;
    }
}

.alignRight {
    display: flex;
    justify-content: flex-end;
}
.cardFooter {
    padding: 0.5rem 1rem;
    .icon {
        margin-right: 0.2rem;
        height: 1rem;
        width: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;

        &.iconSmall{
            height: 0.75rem;
            width: 0.75rem;
        }

        &.iconLarge{
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}

.position {
    cursor: pointer;
    .pin {
        padding-top: 10px;
        color: #16BABB;
        cursor: pointer;
    }
    .label {
        display: block;
        border: 0;
        padding: 0.8rem 0;
        font-weight: 300;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        cursor: pointer;
    }
}
.geoLocation {
    height: 67dvh;
    padding: 0;
    overflow-y: scroll;
    .spaceBetween {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }
}

.colLeft {
    padding-right: 1rem;

    .selectTitle {
        font-weight: bold;
    }

    .selectCol {
        flex: 1;
    }

    .trashButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
    }

    .cardsList {
        flex: 1;
        margin-top: 1rem;
        height: 100%;
        max-height: 80dvh;
        overflow: auto;

        @include scrollbars();

        .totalPoints {
            color: #C5C5C5;
            font-size: small;
            font-style: italic;
        }

        .card {
            background-color: #F6F6F6;
            border: 1px solid #EDEDED;
            border-radius: 0.375rem;
            margin-bottom: 0.5rem !important;
            display: flex;
            flex-direction: column;
            color: #212529;
            word-wrap: break-word;
            background-clip: initial;
            cursor: pointer;
            &.selected {
                background-color: #1786A31A;
                border: solid 3px #1786A330;
            }

            .cardBody {
                padding: 0 1rem 1rem;
            }

            .cardHeader {
                padding: 1rem;
                margin-bottom: 0;
                font-size: 0.9rem;
                display: flex;
                justify-content: space-between;
                .vehicle {
                    display: flex;
                    width: 100%;
                    .image {
                        display: inline-flex;
                        align-items: center;
                        .contentImage {
                            .img {
                                height: 100%;
                                width: 100%;
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-position: center;
                                @include respond-below(lg) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .description {
                        margin-left: 0.8rem;
                        .bold {
                            @include fontWeight("bold");
                            font-size: 1.125rem;
                            white-space: nowrap;
                        }
                        .smallLabel {
                            font-size: 0.875rem;

                        }
                    }
                }
                .driver {
                    .label {
                        margin: 0.8rem;
                        overflow-wrap: break-word;
                    }
                }
            }
        }

    }

    .emptyContainerRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 65dvh;
        border: 1px solid #EDEDED;
        border-radius: 4px;
        margin-top: 1em;
        padding: 2rem;
        background-color: #F6F6F6 ;

        .emptyContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .emptyLabel {
                color: #B4B4B4;
                font-size: 14px;
            }

            .circleButton {
                border: solid 2px lightgrey;
                border-radius: 4px;
                background-color: color('white');
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
    box-shadow: none;
}

.counterList {
    border-radius: 50%;
    background-color: color("primaryDark");
    color: color("white");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: -0.7rem;
    margin-top: -0.6rem;
    padding-left: 0.4rem;
    @include fontWeight("bold");

    left: 2px;
    top: -13px;
    position: relative;
}

.counter {
    border-radius: 50%;
    border: 1px solid color("primaryDark");
    background-color: color("white");
    color: color("primaryDark");
    font-size: 12px;
    display: inline-block;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin-left: 0.8rem;
    @include fontWeight("bold");
}

.counterNumber {
    padding-top: 2px;
}

.filterBadges {
    margin-bottom: 1rem;
}

.descriptionContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include respond-below(xxl) {
        flex-direction: column;
    }

    @include respond-below(lg) {
        flex-direction: row;
    }
}