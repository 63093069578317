@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

$width: 25px;
$height: 25px;

.container {
    display: inline-block;
    position: relative;
    padding-left: 33px;
    // margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: $height;
    min-width: $width;
}

.input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: $height;
    width: $width;
    background-color: color('white');
    border-radius: 2px;
    border: 1px solid color('mercury');

    &.round {
        border-radius: 50%;
    }

    &.disabled {
        background-color: color('catskillWhite');
    }
}

// .container:hover input~.checkMark {
//     background-color: color('white');
// }

// .container input:checked~.checkMark {
    // background-color: #2196F3;
// }

.container .checkMark .icon {
    position: absolute;
    top: 5px;
    left: 5px;
    display: none;
    color: color('primaryDark');
    font-size: 14px;
}

.container input:checked~.checkMark .icon {
    display: block;
}

.label {
    line-height: 25px;
}
