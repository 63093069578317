@import "../../../../../../../styles/utils/functions.scss";
@import "../../../../../../../styles/utils/mixins.scss";
@import "../../../../../../../styles/utils/responsive.scss";

.container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbars();
}

.titleContainer {
    margin-bottom: 17px;
}

.textTitle {
    font-size: 20px;
    font-weight: 'bold';
    @include fontWeight('bold');
    margin-bottom: 10px;
    color: color('primaryDark');
}

.messageContainer {
    margin-bottom: 20px;
}

.textMessage {
    font-size: 16px;
    color: 'blue';
}

.buttons_container {
    margin-top: 1em;
    text-align: right;
};


.header {
    font-size: 1.5rem;
    @include fontWeight('bold');
    margin-bottom: 2rem;

    .icon{
        cursor: pointer;
        width: 1.0625rem;
        margin-right: 0.3rem;
        transform:  rotate(-180deg);
        filter: invert(99%) sepia(0%) saturate(4974%) hue-rotate(45deg) brightness(119%) contrast(49%) opacity(0.6);
        opacity:1
    }
}

.content{
    display: flex;
    flex-direction: row;
    margin: 1rem;
    max-height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbars();
    padding: 1rem;

    @include respond-below(lg) {
        margin: 1rem;
        max-height: 80%;
    }

    @include respond-below(md) {
        margin: 1rem;
        max-height: 80%;
    }

    @include respond-below(sm) {
        margin: 1rem;
        max-height: 75%;
    }

    .label{
        font-size: 1rem;
        margin: 0.67em 0;
        @include fontWeight('bold');
        color: color('black');
    }
    .errorLabel{
        display: block;
        @include fontWeight('light');
        font-size: 14px;
        margin-bottom: 4px;
        color: color('danger');
    }
}


.buttonsContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }

    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}