
body {
    font-family: 'Roboto', sans-serif;
    background-color: color('athensGray');
    height: 100%;
}

html {
    box-sizing: border-box;
    height: 100%;
}

#root {
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.container-wrapper {
    @include containerWrapper;
}

.ql-container.ql-snow.ql-disabled{
    border: none !important
}


.swiper-button-prev, .swiper-button-next {
    color: color('primaryLight')!important;
}

.react-tiny-popover-container{
    max-height: 96%;
    margin-top: max(1px, 1%);
    margin-bottom: min(1, 1%)!important;
}
.react-datepicker-popper {
    z-index:999;
}

.shadow_tooltip {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 14px;
    opacity: unset !important;
}

.leaflet-pane, .leaflet-top, .leaflet-bottom {
    z-index: 1;
}


