@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

$sidebarWidth: 16%;
$sidebarMaxWidth: 19rem;
$sidebarMinWidth: 240px;

.container {
    position: relative;
    display: flex;
    height: 100%;
}

.sidebarContainer {
    width: $sidebarWidth;
    max-width: $sidebarMaxWidth;
    min-width: $sidebarMinWidth;
}

.contentContainer {
    flex: 1;
    overflow: auto;
    @include scrollbars();
}
