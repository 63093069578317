@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";

.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 2rem;
  }
  .toggleSwitch input[type="checkbox"] {
    display: none;
  }
  .toggleSwitch .switch {
    position: absolute;
    border: 1px solid color('gallery');
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    background-color: color('gallery');
  }
  .toggleSwitch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid color('gallery');
    border-radius: 50%;
    transition: transform 0.3s ease;
    background-color: color('white');
  }
  .toggleSwitch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: color('white');
  }
  .toggleSwitch input[type="checkbox"]:checked + .switch {
    background-color: color('primaryDark');
  }

  .enabled{
    cursor: pointer;
  }

  .disabled{
    cursor: not-allowed;
  }