@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.labelAddress {
    background-color: #D0E9EF;
    padding: 0.5rem 1rem;
    border: 1px solid color("mercury");
    border-radius: 10px;
    a {
        color: black;
        text-decoration: none; /* no underline */
        .small {
            font-size: 0.8rem; 
            
        }
        .linkIcon{
            padding: 0 0.5rem;
        }
    }
    .pin {
        color: #16CCC3;
    }
}

.labelDate {
    color: #B4B4B4;
    font-size: 0.75rem;
}

.licensePlate{
    font-weight: bold;
}

.contentImage {
    .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-below(lg) {
            width: 100%;
        }
    }
}