@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";

.container {
    display: inline-flex;
    width: 100%;
    
    .flex{
        flex: 1;
    }
}
