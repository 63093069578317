@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";
@import "styles/theme/variables.scss";

.box {
    width: 100%;
    margin-bottom: 2rem;
}

.header {
    font-size: 1rem;
    @include fontWeight('bold');
    margin-bottom: 1.5rem;

    .icon {
        cursor: pointer;
        width: 1.0625rem;
        margin-right: 1rem;
        transform: rotate(-180deg);
    }
}

.label {
    font-size: 0.9rem;
    margin: 0.67em 0;
    @include fontWeight('bold');
    color: color('black');
}

.buttonsContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1.4;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }

    .button {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.8rem;

        @include respond-below(lg) {
            font-size: 0.7rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }
}

.smallButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 1rem;

    @include respond-below(lg) {
        flex: 1.2;
        margin-left: 0.5rem;
        width: 100%;
    }
}

.map {
    display: flex;
    flex: 1;
    height: 70vh;
    margin: 5px;
}