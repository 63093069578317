@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.container {
    display: flex;
    flex-direction: column;

    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.2rem 0;

        strong {
            color: black;
        }
    }
}